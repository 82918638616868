import { useLayoutEffect } from 'react'

const STATUS_REDIRECT_TARGET = 'https://laserfocus.statuspage.io/'

export default function StatusPageRedirect() {
    if (process.browser) {
        return <Redirect />
    }
    return null
}

function Redirect() {
    useLayoutEffect(() => {
        window.location.href = STATUS_REDIRECT_TARGET
    }, [])
    return null
}
